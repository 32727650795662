import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../bookingCalendar/BookingCalendar.css';
import Payment from '../Payment/Payment';
import PropTypes from 'prop-types';
import { FaCheckCircle, FaTimesCircle, FaInfoCircle, FaCalendarAlt, FaMountain, FaClock, FaDumbbell, FaDollarSign } from 'react-icons/fa';

const BookingCalendar = ({ packageData, onClose, onBook }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNumber: '',
    startDate: '',
    numberOfPeople: ''
  });
  const [showPayment, setShowPayment] = useState(false);
  const [dateStatus, setDateStatus] = useState({});
  const [selectedDate, setSelectedDate] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [bookedDates] = useState(['2024-03-20', '2024-03-25', '2024-04-01']); // In real app, fetch from API

  // Manually blocked dates (add your specific dates here)
  const manuallyBlockedDates = [
    '2025-02-25', // Example blocked date
    '2025-04-10', // Another blocked date
    '2025-05-15', // Festival day
    // Add more dates as needed
  ];

  // Calculate min date (5 days from today)
  const today = new Date();
  const minDate = new Date(today);
  minDate.setDate(today.getDate() + 5);
  
  // Calculate max date (1 year from today)
  const maxDate = new Date(today);
  maxDate.setFullYear(today.getFullYear() + 1);

  useEffect(() => {
    // Simulate fetching available dates from API
    const fetchAvailableDates = async () => {
      // In real implementation, this would be an API call
      const newDateStatus = {};
      
      // Mark dates as available or unavailable
      let currentDate = new Date(minDate);
      while (currentDate <= maxDate) {
        const dateString = currentDate.toISOString().split('T')[0];
        const isBooked = bookedDates.includes(dateString);
        const isTooSoon = currentDate < minDate;
        
        newDateStatus[dateString] = {
          available: !isBooked && !isTooSoon,
          reason: isBooked ? 'Already booked' : 
                 isTooSoon ? 'Must book 5 days in advance' : 'Available'
        };
        
        currentDate.setDate(currentDate.getDate() + 1);
      }
      
      setDateStatus(newDateStatus);
    };

    fetchAvailableDates();
  }, []);

  const handleDateChange = (date) => {
    setStartDate(date);
    setFormData(prev => ({
      ...prev,
      startDate: date.toISOString().split('T')[0]
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!dateStatus[formData.startDate]?.available) {
      alert('Please select an available date');
      return;
    }
    setShowPayment(true);
  };

  // const handlePaymentSuccess = () => {
  //   const modifiedPackageData = {
  //     ...packageData,
  //     packageName: packageData.title
  //   };
  //   onBook(modifiedPackageData, formData.startDate, formData);
  //   onClose();
  // };

  const handlePaymentSuccess = async (bookingResult) => {
    const modifiedPackageData = {
      ...packageData,
      packageName: packageData.title,
    };
  
    const paymentData = {
      amount: totalAmount,
      status: 'success', // Simulating payment status
    };
  
    const bookingDetails = {
      ...formData,
      packageName: modifiedPackageData.packageName,
    };
  
    try {
      const response = await fetch('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/verify', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ paymentData, bookingDetails }),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        alert(result.message);
        onBook(modifiedPackageData, formData.startDate, formData);
        onClose();
      } else {
        alert(result.error);
      }
    } catch (error) {
      console.error('Error verifying payment:', error);
      alert('Payment verification failed. Please try again.');
    }
  };
  

  const totalAmount = packageData?.price * parseInt(formData.numberOfPeople || 1);

  const getDateStatusIndicator = (date) => {
    const status = dateStatus[date];
    if (!status) return null;
    
    return status.available ? (
      <FaCheckCircle className="text-green-500 ml-2" />
    ) : (
      <FaTimesCircle className="text-red-500 ml-2" title={status.reason} />
    );
  };

  // const isDateBooked = (date) => {
  //   return bookedDates.includes(date.toISOString().split('T')[0]);
  // };

  const isDateBlocked = (date) => {
    // Convert date to string format 'YYYY-MM-DD'
    const dateString = date.toISOString().split('T')[0];
    
    // Check if date is manually blocked
    if (manuallyBlockedDates.includes(dateString)) {
      return true;
    }

    // Check if date is within next 5 days
    const fiveDaysFromToday = new Date(today);
    fiveDaysFromToday.setDate(today.getDate() + 5);
    
    return date < fiveDaysFromToday;
  };

  const getDateUnavailableReason = (date) => {
    const dateString = date.toISOString().split('T')[0];
    if (manuallyBlockedDates.includes(dateString)) {
      return "Date not available";
    }
    return "Must book 5 days in advance";
  };

  const renderDayContents = (day, date) => {
    const isBlocked = isDateBlocked(date);
    
    return (
      <div className={`custom-day ${isBlocked ? 'blocked' : 'available'}`}>
        <span>{day}</span>
        {isBlocked ? (
          <FaTimesCircle 
            className="day-indicator unavailable" 
            title={getDateUnavailableReason(date)}
          />
        ) : (
          <FaCheckCircle className="day-indicator available" />
        )}
      </div>
    );
  };

  if (!packageData) {
    return <div>Loading package details...</div>;
  }

  return (
    <div className="booking-calendar-overlay">
      <div className="booking-calendar-modal">
        <div className="booking-header">
          <div className="flex items-center justify-between mb-2">
            <h2 className="text-xl font-semibold text-gray-800">{packageData?.title}</h2>
            <button className="close-btn" onClick={onClose}>&times;</button>
          </div>
          
          <div className="package-info-grid">
            <div className="info-pill">
              <FaClock className="info-icon" />
              <span>{packageData?.duration}</span>
            </div>
            <div className="info-pill">
              <FaDumbbell className="info-icon" />
              <span>{packageData?.difficulty}</span>
            </div>
            <div className="info-pill">
              <FaDollarSign className="info-icon" />
              <span>${packageData?.price}/person</span>
            </div>
          </div>

          <div className="booking-notice-compact">
            <FaInfoCircle className="text-blue-500" />
            <span>5 days advance booking required</span>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="booking-form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input 
              type="text" 
              id="name" 
              name="name" 
              value={formData.name} 
              onChange={handleChange} 
              required 
              className="form-input"
              placeholder="Enter your full name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input 
              type="email" 
              id="email" 
              name="email" 
              value={formData.email} 
              onChange={handleChange} 
              required 
              className="form-input"
              placeholder="Enter your email"
            />
          </div>

          <div className="form-group">
            <label htmlFor="contactNumber">Contact Number</label>
            <input 
              type="tel" 
              id="contactNumber" 
              name="contactNumber" 
              value={formData.contactNumber} 
              onChange={handleChange} 
              required 
              className="form-input"
              placeholder="Enter your contact number"
            />
          </div>

          <div className="form-group date-picker-container">
            <label htmlFor="startDate" className="date-label">
              Start Date
              <FaCalendarAlt className="calendar-icon" />
            </label>
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              minDate={minDate}
              maxDate={maxDate}
              dateFormat="MMMM d, yyyy"
              placeholderText="Select your trek date"
              renderDayContents={renderDayContents}
              inline
              calendarClassName="custom-calendar"
              dayClassName={date =>
                isDateBlocked(date) ? 'blocked-day' : 'available-day'
              }
              filterDate={date => !isDateBlocked(date)}
            />
            {startDate && isDateBlocked(startDate) && (
              <span className="date-error">
                {getDateUnavailableReason(startDate)}
              </span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="numberOfPeople">Number of People</label>
            <select
              id="numberOfPeople"
              name="numberOfPeople"
              value={formData.numberOfPeople}
              onChange={handleChange}
              required
              className="form-input"
            >
              <option value="">Select number of people</option>
              {[...Array(25)].map((_, i) => (
                <option key={i + 1} value={i + 1}>{i + 1}</option>
              ))}
            </select>
          </div>

          <div className="total-amount">
            <span className="label">Total Amount:</span>
            <span className="value">${totalAmount}</span>
          </div>

          <div className="form-actions">
            <button type="submit" className="submit-btn" disabled={!dateStatus[formData.startDate]?.available}>
              Book Now
            </button>
            <button type="button" className="cancel-btn" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
        {/* // Payment component rendering */}
  {showPayment && (
    <Payment
      amount={totalAmount}
      onPaymentSuccess={handlePaymentSuccess}
      onPaymentCancel={() => setShowPayment(false)}
      bookingDetails={{
        packageData: {
          ...packageData,
          packageName: packageData.title
        },
        date: formData.startDate,
        formData: {
          name: formData.name,
          email: formData.email,
          contactNumber: formData.contactNumber
        },
        numberOfPeople: parseInt(formData.numberOfPeople || 1)
      }}
    />
  )}
      </div>
    </div>
  );
};

BookingCalendar.propTypes = {
  packageData: PropTypes.shape({
    title: PropTypes.string,
    duration: PropTypes.string,
    difficulty: PropTypes.string,
    price: PropTypes.number
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onBook: PropTypes.func.isRequired
};

export default BookingCalendar;