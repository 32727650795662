import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
// import { createBrowserRouter } from 'react-router-dom';


console.log('API URL:', import.meta.env.VITE_API_URL); // Check if it's defined
// const router = createBrowserRouter([])

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
