import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { OrbitProgress } from 'react-loading-indicators';
import { useNavigate } from 'react-router-dom';
//import '../../Components/Package/styles.css'; // Optional: Add custom styles for this component

const Adminpackagelist = () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Fetch packages
  useEffect(() => {
    const fetchPackages = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/package/all');
        setPackages(response.data);
        setError(null);
      } catch (error) {
        console.error('Failed to fetch packages:', error);
        setError('Failed to fetch packages. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchPackages();
  }, []);

  // Handle delete package
  const handleDeletePackage = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this package?');
    if (!confirmDelete) return;

    try {
      const response = await axios.delete(`https://nodejs-serverless-function-express-henna-theta.vercel.app/api/package/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`, // Include token for authorization
        },
      });

      alert(response.data.message);
      // Refresh packages after deletion
      setPackages((prevPackages) => prevPackages.filter((pkg) => pkg._id !== id));
    } catch (error) {
      console.error('Error deleting package:', error);
      alert('Failed to delete package. Please try again.');
    }
  };

  // Handle update package
  const handleUpdatePackage = (id) => {
    navigate(`/update-package/${id}`); // Redirect to a new page to edit the package
  };

  // Handle create package
  const handleCreatePackage = () => {
    navigate('/PackageUpload'); // Redirect to the create package page
  };

  if (loading) return <div className="loader"><OrbitProgress color="#32cd32" size="large" /></div>;
  if (error) return <p className="error-message">{error}</p>;
  if (packages.length === 0) return <div className="no-packages">No packages found. Please add some packages.</div>;

  return (
    <div>
      <button
        className="border bg-green-400 px-4 py-2 text-white mx-4 mt-4 rounded"
        onClick={handleCreatePackage}
      >
        Create Package +
      </button>
      <div className="admin-package-list">
        <h2 className="text-3xl font-bold mt-6">Package List</h2>
        <div className="package-grid">
          {packages.map((pkg) => (
            <div key={pkg._id} className="package-card">
              <img
                src={pkg.overallImages && pkg.overallImages[0]}
                alt={pkg.title}
                className="package-image"
              />
              <div className="package-details">
                <h3 className="text-lg font-bold">{pkg.title}</h3>
                <p className="package-description">{pkg.description}</p>
                <div className="package-meta">
                  <span>Price: ${pkg.price}</span>
                  <span>Duration: {pkg.duration} days</span>
                  <span>Difficulty: {pkg.difficulty}</span>
                </div>
                <div className="buttons flex justify-between mt-4">
                  <button
                    className="border bg-red-400 px-4 py-2 text-white rounded"
                    onClick={() => handleDeletePackage(pkg._id)}
                  >
                    Delete
                  </button>
                  <button
                    className="border bg-green-400 px-4 py-2 text-white rounded"
                    onClick={() => handleUpdatePackage(pkg._id)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Adminpackagelist;
