import React from 'react';
import '../Background/Background.css'
import vid from '../../assets/vidEO.mp4'
// import mmm from '../../assets/mmm.jpg'
// import him from '../../assets/him.jpg'
// import rular from '../../assets/rular.jpg'


const Background = ({ playStatus, heroCount }) => {
  return (
    <div className="background">
      {playStatus && (
        <video
          autoPlay
          loop
          muted
          playsInline // Prevents full-screen mode on iOS
          className="background-video"
        >
          <source src={vid} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      {!playStatus && heroCount === 0 && <img src={him} className='background' alt="" />}
      {!playStatus && heroCount === 1 && <img src={rular} className='background' alt="" />}
      {!playStatus && heroCount === 2 && <img src={mmm} className='background' alt="" />}
    </div>
  );
};

export default Background;
