import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {   Loader2, MapPin, Globe, Calendar, Map, CheckCircle, XCircle,
  Star, Users, Award, Shield, Compass, Mountain, Heart, Share2 } from 'lucide-react';
import axios from 'axios';
import './PackageDetail.css';
import CampaignSection from '../campaign/CampaignSection';
import BookingCalendar from '../bookingCalendar/BookingCalendar';
import Footer from '../footer/Footer';



const PackageDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  const [packageData, setPackageData] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const [activeDay, setActiveDay] = useState(1);
  const [showFullGallery, setShowFullGallery] = useState(false);

  const handleBookNow = () => {
    console.log('clicked book now', slug)
    // setSelectedPackage(true);
    setSelectedPackage(packageData); 
  };
  const handleCloseBooking = () => {
    setSelectedPackage(null);
  };

  const handleBooking = (date, formData) => {
    // console.log('handle booking')
    setBookingDetails({ packageData: selectedPackage, date, formData });
    setIsPaymentOpen(true);
  };

  // useEffect(() => {
  //   console.log(`slug   ${slug}`)
  // //   const fetchPackages = async () => {
  // //     try {
  // //       const response = await axios.get('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/packages');
  // //       if (Array.isArray(response.data)) {
  // //         setPackages(response.data);
  // //       } else {
  // //         throw new Error('Invalid data format');
  // //       }
  // //     } catch (error) {
  // //       console.error('Error fetching packages:', error);
  // //       setError(error.response?.data?.message || 'Failed to load packages');
  // //     } finally {
  // //       setLoading(false);
  // //     }
  // //   };
  // //   fetchPackages();
  // // }, []);
  const GoogleMapEmbed = ({ location }) => (
    <div className="h-[400px] rounded-xl overflow-hidden mb-6">
      <iframe
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: 0 }}
        src={`https://www.google.com/maps/embed/v1/place?key=YOUR_GOOGLE_MAPS_API_KEY&q=${location}`}
        allowFullScreen
      />
    </div>
  );

  const HighlightBanner = () => (
    <div className="bg-indigo-50 p-6 rounded-xl mb-8">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
        <div className="flex items-center space-x-3">
          <Users className="w-8 h-8 text-indigo-600" />
          <div>
            <p className="font-semibold">Small Groups</p>
            <p className="text-sm text-gray-600">Max 12 people</p>
          </div>
        </div>
        <div className="flex items-center space-x-3">
          <Shield className="w-8 h-8 text-indigo-600" />
          <div>
            <p className="font-semibold">Safety First</p>
            <p className="text-sm text-gray-600">Certified Guides</p>
          </div>
        </div>
        <div className="flex items-center space-x-3">
          <Award className="w-8 h-8 text-indigo-600" />
          <div>
            <p className="font-semibold">Best Price</p>
            <p className="text-sm text-gray-600">Guaranteed</p>
          </div>
        </div>
        <div className="flex items-center space-x-3">
          <Star className="w-8 h-8 text-indigo-600" />
          <div>
            <p className="font-semibold">4.9/5 Rating</p>
            <p className="text-sm text-gray-600">From 200+ reviews</p>
          </div>
        </div>
      </div>
    </div>
  );

  const QuickActions = () => (
    <div className="flex justify-end space-x-4 mb-4">
      <button className="flex items-center text-gray-600 hover:text-indigo-600">
        <Heart className="w-5 h-5 mr-1" /> Save
      </button>
      <button className="flex items-center text-gray-600 hover:text-indigo-600">
        <Share2 className="w-5 h-5 mr-1" /> Share
      </button>
    </div>
  );

  const WeatherWidget = () => (
    <div className="bg-white rounded-xl p-4 mb-6">
      <h3 className="font-semibold mb-2">Current Weather</h3>
      <div className="flex items-center justify-between">
        <div className="text-3xl font-bold">22°C</div>
        <div className="text-gray-600">Partly Cloudy</div>
      </div>
    </div>
  );

  const TestimonialsSection = () => (
    <div className="bg-white rounded-2xl shadow-lg p-8 mt-8">
      <h2 className="text-2xl font-bold text-gray-900 mb-6">What Trekkers Say</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {[1, 2].map((index) => (
          <div key={index} className="bg-gray-50 p-6 rounded-xl">
            <div className="flex items-center mb-4">
              <div className="w-12 h-12 rounded-full bg-gray-300 mr-4" />
              <div>
                <p className="font-semibold">Happy Trekker {index}</p>
                <div className="flex text-yellow-400">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="w-4 h-4 fill-current" />
                  ))}
                </div>
              </div>
            </div>
            <p className="text-gray-600">
              "An incredible experience! The guides were knowledgeable and the views were breathtaking."
            </p>
          </div>
        ))}
      </div>
    </div>
  );

  const PreparationChecklist = () => (
    <div className="bg-white rounded-xl p-6 mb-6">
      <h3 className="text-xl font-bold mb-4">Preparation Checklist</h3>
      <ul className="space-y-2">
        {[
          "Physical fitness training",
          "Required documentation",
          "Essential gear checklist",
          "Weather preparation",
          "Altitude acclimatization"
        ].map((item, index) => (
          <li key={index} className="flex items-center">
            <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
            {item}
          </li>
        ))}
      </ul>
    </div>
  );


  useEffect(() => {
    const fetchPackages = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://nodejs-serverless-function-express-henna-theta.vercel.app/api/package/${slug}`);
        setPackageData(response.data);
        if (response.data.overallImages.length > 0) {
          setSelectedImage(response.data.overallImages[0]);
        }
      } catch (error) {
        console.error('Error fetching package:', error);
        setError(error.response?.data?.message || 'Failed to load package details');
      } finally {
        setLoading(false);
      }
    };

    if (slug) {
      fetchPackages();
    }
  }, [slug]);

  if (loading)
    return (
  //loading spinner display 
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );

  if (error) {
    return (
      <div className="error-container">
        <h3>Error Loading Package</h3>
        <p>{error}</p>
        <button onClick={() => navigate(-1)}>Go Back</button>
      </div>
    );
  }

  if (!packageData) {
    return (
      <div className="error-container">
        <h3>Package Not Found</h3>
        <p>The requested package could not be found.</p>
        <button onClick={() => navigate(-1)}>Go Back</button>
      </div>
    );
  }

    return (
      <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 bg-gray-50 mt-5">
        <header className="mb-8">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">{packageData.title}</h1>
          <div className="flex items-center space-x-4 text-gray-600">
            <div className="flex items-center">
              <Calendar className="w-5 h-5 mr-2 text-indigo-600" />
              <span>{packageData.duration}</span>
            </div>
            <div className="w-1.5 h-1.5 rounded-full bg-gray-400" />
            <div className="flex items-center">
              <MapPin className="w-5 h-5 mr-2 text-indigo-600" />
              <span>{packageData.difficulty}</span>
            </div>
            <div className="w-1.5 h-1.5 rounded-full bg-gray-400" />
            <span className="text-2xl font-bold text-indigo-600">${packageData.price}</span>
          </div>
        </header>
  
        <section className="mb-12">
          <div className="relative rounded-2xl overflow-hidden shadow-2xl">
            <img 
              src={selectedImage || packageData.overallImages[0]} 
              alt={packageData.title}
              className="w-full h-[600px] object-cover"
            />
            <button
              className="absolute bottom-6 right-6 bg-indigo-600 text-white px-8 py-4 rounded-lg font-semibold 
              hover:bg-indigo-700 transform transition hover:scale-105 shadow-lg"
              onClick={(e) => {
                e.stopPropagation();
                handleBookNow();
              }}
            >
              Book Your Adventure
            </button>
          </div>
          <div className="grid grid-cols-5 gap-4 mt-4">
            {packageData.overallImages.map((img, index) => (
              <div
                key={index}
                className={`cursor-pointer rounded-lg overflow-hidden transform transition hover:scale-105
                ${selectedImage === img ? 'ring-2 ring-indigo-600' : ''}`}
                onClick={() => setSelectedImage(img)}
              >
                <img src={img} alt={`View ${index + 1}`} className="w-full h-24 object-cover" />
              </div>
            ))}
          </div>
        </section>
  
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <section className="lg:col-span-2">
            <div className="bg-white rounded-2xl shadow-lg p-8 mb-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Overview</h2>
              <p className="text-gray-600 leading-relaxed">{packageData.description}</p>
            </div>
  
            <div className="bg-white rounded-2xl shadow-lg p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Daily Itinerary</h2>
              {packageData.itinerary.map((day, index) => (
                <div key={index} className="mb-8 last:mb-0">
                  <div className="border-l-4 border-indigo-600 pl-4">
                    <h3 className="text-xl font-bold text-gray-900 mb-2">Day {day.day}: {day.title}</h3>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4 text-sm text-gray-600">
                      <div className="flex items-center">
                        <Map className="w-4 h-4 mr-2 text-indigo-600" />
                        <span>{day.distance}</span>
                      </div>
                      <div className="flex items-center">
                        <Calendar className="w-4 h-4 mr-2 text-indigo-600" />
                        <span>{day.duration}</span>
                      </div>
                      <div>↑ {day.ascent}</div>
                      <div>↓ {day.descent}</div>
                    </div>
                  </div>
                  <p className="text-gray-600 mt-2">{day.description}</p>
                  {day.imageUrl && (
                    <img
                      src={day.imageUrl}
                      alt={`Day ${day.day} - ${day.title}`}
                      className="w-full h-48 object-cover rounded-lg mt-4"
                    />
                  )}
                </div>
              ))}
            </div>
          </section>
  
          <aside className="lg:col-span-1">
            <div className="bg-white rounded-2xl shadow-lg p-6 mb-6 sticky top-6">
              <h3 className="text-xl font-bold text-gray-900 mb-4">Trip Details</h3>
              <div className="grid grid-cols-2 gap-4">
                <div className="p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-center text-indigo-600 font-semibold mb-2">
                    <MapPin className="w-4 h-4 mr-2" /> Region
                  </div>
                  <p className="text-gray-600">{packageData.region}</p>
                </div>
                <div className="p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-center text-indigo-600 font-semibold mb-2">
                    <Globe className="w-4 h-4 mr-2" /> Country
                  </div>
                  <p className="text-gray-600">{packageData.country}</p>
                </div>
                <div className="p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-center text-indigo-600 font-semibold mb-2">
                    <Map className="w-4 h-4 mr-2" /> Daily Distance
                  </div>
                  <p className="text-gray-600">{packageData.dailyDistance}</p>
                </div>
                <div className="p-4 bg-gray-50 rounded-lg">
                  <div className="text-indigo-600 font-semibold mb-2">
                    Daily Ascent
                  </div>
                  <p className="text-gray-600">{packageData.dailyAscent}</p>
                </div>
              </div>
  
              <div className="mt-6">
                <h3 className="flex items-center text-xl font-bold text-gray-900 mb-4">
                  <CheckCircle className="w-5 h-5 mr-2 text-green-500" /> What's Included
                </h3>
                <ul className="space-y-2">
                  {packageData.included.map((item, index) => (
                    <li key={index} className="flex items-center text-gray-600">
                      <CheckCircle className="w-4 h-4 mr-2 text-green-500" /> {item}
                    </li>
                  ))}
                </ul>
              </div>
  
              <div className="mt-6">
                <h3 className="flex items-center text-xl font-bold text-gray-900 mb-4">
                  <XCircle className="w-5 h-5 mr-2 text-red-500" /> What's Not Included
                </h3>
                <ul className="space-y-2">
                  {packageData.excluded.map((item, index) => (
                    <li key={index} className="flex items-center text-gray-600">
                      <XCircle className="w-4 h-4 mr-2 text-red-500" /> {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <CampaignSection />
          </aside>
        </div>
      {selectedPackage && (
        <BookingCalendar
          packageData={selectedPackage}
          onClose={handleCloseBooking}
          onBook={handleBooking}
        />
      )}
    </div>
    <Footer />
</>
  );
};

export default PackageDetail;
