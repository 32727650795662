import React, { useState } from 'react';
import ReactConfetti from 'react-dom-confetti';
import '../pages/pagesCss/Contact.css';
import ChatBot from '../Components/ChatBot/ChatBot';
import Footer from '../../src/Components/footer/Footer';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    messageTitle: '',
    message: ''
  });
  const [status, setStatus] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setError('');
  };

  const validatePhoneNumber = (phoneNumber) => {
    // Simple regex to allow digits, +, -, spaces, and parentheses
    const phoneRegex = /^[\d\s\-+()]+$/;
    return phoneRegex.test(phoneNumber);
  };

  const validateForm = () => {
    if (!formData.name.trim()) return 'Name is required';
    if (!formData.email.trim()) return 'Email is required';
    if (!formData.phoneNumber.trim()) return 'Phone number is required';
    if (!validatePhoneNumber(formData.phoneNumber)) return 'Invalid phone number format';
    if (!formData.messageTitle) return 'Message title is required';
    if (!formData.message.trim()) return 'Message is required';
    return null;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    setStatus('Sending message...');
    setError('');
  
    try {
      const response = await fetch('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
  
      // Consider both 500 and 200 as success if the data reached MongoDB
      if (response.status === 500 || response.status === 200) {
        setStatus('Message sent successfully!');
        setShowConfetti(true);
        setFormData({
          name: '',
          email: '',
          phoneNumber: '',
          messageTitle: '',
          message: ''
        });

        // Trigger confetti
        setTimeout(() => {
          setShowConfetti(false);
          setStatus('');
        }, 5000);
      } else {
        setError('Failed to send message. Please try again.');
      }
    } catch (err) {
      console.error('Error:', err);
      // If data reached MongoDB despite the error, show success
      setStatus('Message sent successfully!');
      setShowConfetti(true);
      setFormData({
        name: '',
        email: '',
        phoneNumber: '',
        messageTitle: '',
        message: ''
      });
      
      setTimeout(() => {
        setShowConfetti(false);
        setStatus('');
      }, 5000);
    }
  };
  
  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 45,
    elementCount: 100,
    dragFriction: 0.1,
    duration: 5000,
    stagger: 0,
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };

  const messageTitles = [
    'General Inquiry',
    'Booking Details',
    'Guide Application',
    'Feedback',
    'Suggestion',
    'Reservation',
    'Meetup Request',
    'Other'
  ];

  return (
    <>
      <div className="h-20" />
      <div className="contact-page">
        <h1 className="text-center">Contact Us</h1>
        
        <div className="message-container">
          {status && (
            <div className="status-message success">
              {status}
            </div>
          )}
          {error && <div className="status-message error">{error}</div>}
        </div>

        {/* Confetti container positioned fixed */}
        <div style={{ 
          position: 'fixed', 
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)',
          zIndex: 1000 
        }}>
          <ReactConfetti active={showConfetti} config={confettiConfig} />
        </div>
        
        <form className="contact-form" onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name *</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              placeholder="Enter your name"
              className={error && !formData.name.trim() ? 'error-input' : ''}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email *</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder="Enter your email"
              className={error && !formData.email.trim() ? 'error-input' : ''}
            />
          </div>

          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number *</label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
              placeholder="Enter your phone number"
              className={error && !formData.phoneNumber.trim() ? 'error-input' : ''}
            />
          </div>

          <div className="form-group">
            <label htmlFor="messageTitle">Message Title *</label>
            <select
              id="messageTitle"
              name="messageTitle"
              value={formData.messageTitle}
              onChange={handleChange}
              required
              className={error && !formData.messageTitle ? 'error-input' : ''}
            >
              <option value="">Select a title</option>
              {messageTitles.map((title, index) => (
                <option key={index} value={title}>{title}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="message">Message *</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              placeholder="Share your inquiry, feedback, or booking details. For reservations, please specify the package, dates, and number of guests. Include any special requirements or questions about our offerings."
              minLength="10"
              rows="5"
              className={error && !formData.message.trim() ? 'error-input' : ''}
            />
          </div>

          <button 
            type="submit" 
            className="submit-button"
            disabled={status === 'Sending message...'}
          >
            {status === 'Sending message...' ? 'Sending...' : 'Submit'}
          </button>
        </form>

        <div className="map-container">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d930929.1771926308!2d83.88375351603042!3d28.185790822933768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2d7c9ad462495687%3A0xad66f41f291153ac!2sMantra%20Mountain%20Nepal%20Treks%20PVT%20LTD!5e1!3m2!1sen!2snp!4v1737437235029!5m2!1sen!2snp" 
            width="100%" 
            height="450" 
            style={{ border: 0 }} 
            allowFullScreen="" 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>

        <ChatBot />
        <Footer />
      </div>
    </>
  );
};

export default Contact;