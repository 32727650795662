import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaClock, FaMountain, FaMapMarkerAlt, FaDollarSign, FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { OrbitProgress } from 'react-loading-indicators';

const Swiper = () => {
  const [packages, setPackages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get(
          'https://nodejs-serverless-function-express-henna-theta.vercel.app/api/package/all'
        );
        if (Array.isArray(response.data)) {
          setPackages(response.data);
        }
      } catch (error) {
        console.error('Error fetching packages:', error);
      }
    };
    fetchPackages();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % packages.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [packages.length]);

  const handlePackageClick = (slug) => {
    navigate(`/packages/${slug}`);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + packages.length) % packages.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % packages.length);
  };

  return (
    <section className="py-16 bg-gradient-to-b">
      <div className="container mx-auto px-4 max-w-7xl">
        <div className="text-center mb-12">
          <h3 className="text-indigo-600 font-semibold text-lg mb-2">
            - Transformative Journeys Await -
          </h3>
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
            Discover Your True Self in Nepal's Majestic Himalayas
          </h1>
          <div className="w-24 h-1 bg-indigo-600 mx-auto rounded-full"></div>
        </div>

        {packages.length > 0 ? (
          <div className="relative">
            <AnimatePresence mode="wait">
              <motion.div
                key={currentIndex}
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.5 }}
                className="relative"
              >
                <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
                  <div className="grid md:grid-cols-2 gap-0">
                    {/* Image Section */}
                    <div className="relative h-[300px] md:h-[500px]">
                      <img
                        src={packages[currentIndex].overallImages[0]}
                        alt={packages[currentIndex].title}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
                      <div className="absolute top-4 right-4">
                        <span className="px-4 py-2 bg-white/90 text-indigo-600 rounded-full text-sm font-semibold shadow-lg">
                          {packages[currentIndex].difficulty}
                        </span>
                      </div>
                    </div>

                    {/* Content Section */}
                    <div className="p-8 flex flex-col justify-between bg-white">
                      <div>
                        <h2 className="text-3xl font-bold text-gray-900 mb-4">
                          {packages[currentIndex].title}
                        </h2>
                        <p className="text-gray-600 mb-6 line-clamp-3">
                          {packages[currentIndex].description}
                        </p>
                        
                        <div className="grid grid-cols-2 gap-4 mb-8">
                          <div className="flex items-center space-x-2 text-gray-600">
                            <FaClock className="text-indigo-600 text-xl" />
                            <span>{packages[currentIndex].duration} Days</span>
                          </div>
                          <div className="flex items-center space-x-2 text-gray-600">
                            <FaMapMarkerAlt className="text-indigo-600 text-xl" />
                            <span>{packages[currentIndex].region}</span>
                          </div>
                          <div className="flex items-center space-x-2 text-gray-600">
                            <FaMountain className="text-indigo-600 text-xl" />
                            <span>{packages[currentIndex].difficulty}</span>
                          </div>
                          <div className="flex items-center space-x-2 text-gray-600">
                            <FaDollarSign className="text-indigo-600 text-xl" />
                            <span>From ${packages[currentIndex].price}</span>
                          </div>
                        </div>
                      </div>

                      <div className="mt-auto">
                        <button
                          onClick={() => handlePackageClick(packages[currentIndex].slug)}
                          className="w-full bg-indigo-600 text-white py-3 px-6 rounded-xl hover:bg-indigo-700 
                            transition-all duration-300 flex items-center justify-center space-x-2 group"
                        >
                          <span>Explore This Trek</span>
                          <FaArrowRight className="group-hover:translate-x-1 transition-transform" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </AnimatePresence>

            {/* Navigation Buttons */}
            <button
              onClick={handlePrev}
              className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-3 rounded-full 
                shadow-lg backdrop-blur-sm transition-all duration-300 z-10"
            >
              <FaArrowLeft className="text-gray-800 text-xl" />
            </button>
            <button
              onClick={handleNext}
              className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-3 rounded-full 
                shadow-lg backdrop-blur-sm transition-all duration-300 z-10"
            >
              <FaArrowRight className="text-gray-800 text-xl" />
            </button>

            {/* Package Counter */}
            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 bg-white/80 backdrop-blur-sm 
              px-4 py-2 rounded-full shadow-lg">
              <span className="text-gray-800 font-medium">
                {currentIndex + 1} / {packages.length}
              </span>
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center h-[400px]">
            <OrbitProgress color="#4f46e5" size="large" text="" textColor="" />
          </div>
        )}
      </div>
    </section>
  );
};

export default Swiper;
