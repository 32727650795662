import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './styles.css';
import Footer from '../footer/Footer';
import { OrbitProgress } from 'react-loading-indicators';

const BlogList = () => {
    const [blogs, setBlogs] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`https://nodejs-serverless-function-express-henna-theta.vercel.app/api/all`);
                if (Array.isArray(response.data)) {
                    setBlogs(response.data);
                    setError(null);
                } else {
                    throw new Error('Invalid data format');
                }
            } catch (error) {
                console.error('Failed to fetch blogs:', error);
                setError(error.response?.data?.message || 'Failed to fetch blogs. Please try again later.');
                setBlogs([]);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);
    
    
  if (loading)
    return (
  //loading spinner display 
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
    
    if (error) return (
        <div className="min-h-screen flex items-center justify-center text-red-500 text-xl">
            Error: {error}
        </div>
    );
    
    if (blogs.length === 0) return (
        <div className="min-h-screen flex items-center justify-center text-gray-500 text-xl">
            No blogs found. Please add some blogs.
        </div>
    );

    return (
        <>
            <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 mt-5">
                <div className="max-w-7xl mx-auto">
                    <h1 className="mb-4 text-md text-center text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                        <span className="bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-transparent">
                            Explore Our Blogs
                        </span>
                    </h1>
                    
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                        {blogs.map((blog) => (
                            <Link 
                                to={`/blog/${blog.slug}`} 
                                key={blog.slug} 
                                className="transform transition duration-300 hover:scale-105 hover:shadow-xl"
                            >
                                <div className="relative h-96 rounded-xl shadow-lg overflow-hidden bg-white">
                                    {/* Image Section */}
                                    {blog.image && (
                                        <div className="relative h-60 overflow-hidden">
                                            <img 
                                                src={blog.image} 
                                                alt={blog.title}
                                                className="w-full h-full object-cover transition-transform duration-300 hover:scale-110"
                                            />
                                            <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent" />
                                        </div>
                                    )}
                 {/* Image Section */}
                  {blog.images.length > 0 && (
                    <div className="relative h-60 overflow-hidden">
                      {blog.images.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt={`Blog image ${index + 1}`}
                          className="w-full h-60 object-cover transition-transform duration-300 hover:scale-110"
                        />
                      ))}
                    </div>
                  )}
                                    
                                    {/* Content Section */}
                                    <div className="p-6">
                                        <h2 className="text-xl font-bold text-gray-900 mb-2 line-clamp-2">
                                            {blog.title}
                                        </h2>
                                        <div className="flex flex-wrap gap-2">
                                            {blog.Slug?.map((word, index) => (
                                                <span 
                                                    key={index}
                                                    className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm"
                                                >
                                                    #{word}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default BlogList;