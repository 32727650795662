import React, { useState, useEffect } from 'react';
import { FaGlobe, FaTimes } from 'react-icons/fa';

const FloatingTranslator = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const addGoogleTranslateScript = () => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';

      window.googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          { pageLanguage: 'en' },
          'google_translate_element'
        );
      };

      document.body.appendChild(script);
    };

    if (!document.querySelector('script[src*="translate.google.com"]')) {
      addGoogleTranslateScript();
    }
  }, []);

  return (
    <>
      <div className="fixed bottom-24 right-4 z-50 flex flex-col items-end">
        <button 
          onClick={() => setIsOpen(!isOpen)}
          className="w-12 h-12 rounded-full bg-blue-600 text-white shadow-lg flex items-center justify-center hover:bg-blue-700 transition-all duration-300 border border-blue-500"
          aria-label={isOpen ? 'Close translator' : 'Open translator'}
        >
          {isOpen ? <FaTimes className="w-5 h-5" /> : <FaGlobe className="w-6 h-6" />}
        </button>
        
        {isOpen && (
          <div className="mt-4 bg-white rounded-lg shadow-xl border border-gray-200 overflow-hidden w-[300px] transition-transform transform scale-100">
            <div className="p-4">
              <h3 className="text-lg font-semibold text-gray-800 mb-4 text-center flex items-center justify-center gap-2">
                <FaGlobe className="w-5 h-5" />
                Select Language
              </h3>
              <div id="google_translate_element" className="translate-dropdown"></div>
            </div>
          </div>
        )}
      </div>

      <style jsx global>{`
        .goog-te-banner-frame {
          display: none !important;
        }
        
        body {
          top: 0 !important;
        }
        
        .goog-te-gadget {
          margin: 0 !important;
        }
        
        .goog-te-gadget select {
          width: 100% !important;
          padding: 8px !important;
          border: 1px solid #e2e8f0 !important;
          border-radius: 8px !important;
          font-size: 14px !important;
          color: #4a5568 !important;
          background-color: white !important;
        }
        
        .goog-te-gadget span {
          display: none !important;
        }

        /* Additional styles for the dropdown */
        .translate-dropdown {
          max-height: 300px;
          overflow-y: auto;
        }
      `}</style>
    </>
  );
};

export default FloatingTranslator; 