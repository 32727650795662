import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { OrbitProgress } from 'react-loading-indicators';
import CreateBlog from './CreateBlog';
import '../../Components/BlogPost/styles.css';
import Footer from '../footer/Footer';

const Adminbloglist = () => {
  const [showCreateBlog, setShowCreateBlog] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleCreateButton = () => setShowCreateBlog(true);
  const handleCloseCreate = () => setShowCreateBlog(false);

  // Fetch blogs
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://nodejs-serverless-function-express-henna-theta.vercel.app/api/all`);
        setBlogs(response.data);
        setError(null);
      } catch (error) {
        console.error('Failed to fetch blogs:', error);
        setError('Failed to fetch blogs. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    fetchBlogs();
  }, []);

  // Delete blog
  const handleDeleteBlog = async (id) => {
    // Confirm before deleting the blog
    const confirmDelete = window.confirm('Are you sure you want to delete this blog?');
    if (!confirmDelete) return;
  
    // Check if the admin token is available
    const adminToken = localStorage.getItem('adminToken');
    if (!adminToken) {
      alert('You are not authorized to perform this action. Please log in as an admin.');
      return;
    }
  
    try {
      // Make the delete request
      const response = await axios.delete(`https://nodejs-serverless-function-express-henna-theta.vercel.app/api/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${adminToken}`, // Include token for authorization
        },
      });
  
      // Handle response
      if (response.status === 200) {
        setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog._id !== id));
        alert(response.data.message || 'Blog deleted successfully!');
      } else {
        alert(response.data.message || 'Failed to delete the blog.');
      }
    } catch (error) {
      // Handle error response
      const errorMessage = error.response?.data?.message || 'Something went wrong while deleting the blog.';
      alert(errorMessage);
      console.error('Error deleting blog:', error);
    }
  };
  
  // Navigate to update blog page
  const handleUpdateBlog = (id) => {
    navigate(`/update-blog/${id}`); // Redirect to a new page to edit the blog
  };

  if (loading) return <div className="loader"><OrbitProgress color="#32cd32" size="large" /></div>;
  if (error) return <div className="error-message">{error}</div>;
  if (blogs.length === 0) return <div className="no-blogs">No blogs found. Please add some blogs.</div>;

  return (
    <div>
      <button className="border bg-green-400 px-2 py-1 text-white mx-2" onClick={handleCreateButton}>
        Create Blog +
      </button>
      {showCreateBlog && <CreateBlog onClose={handleCloseCreate} />}

      <div className="blog-container">
        <h1 className="text-3xl font-bold">Blog List</h1>
        <div className="blog-grid">
          {blogs.map((blog) => (
            <div key={blog._id} className="blog-card">
              <div className="blog-card-inner">
                <div className="buttons flex justify-between">
                  <button
                    className="border bg-red-400 px-2 py-2"
                    onClick={() => handleDeleteBlog(blog._id)}
                  >
                    Delete
                  </button>
                  <button
                    className="border bg-green-400 px-2 py-2"
                    onClick={() => handleUpdateBlog(blog._id)}
                  >
                    Update
                  </button>
                </div>
                {blog.image && <img src={blog.image} alt={blog.title} />}
                <h2>{blog.title}</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Adminbloglist;
