import React from 'react';
import '../pages/pagesCss/About.css';
import ChatBot from '../Components/ChatBot/ChatBot';
import TravelHimalayaLogo from '../assets/travel-himalayan-logo.png';
import { Link } from 'react-router-dom';
import CampaignSection from '../Components/campaign/CampaignSection';
import Footer from '../Components/footer/Footer'
import AboutUs from '../Components/AboutUS/AboutUs'

const About = () => {
  return (
    <>
    <div className="about-container">
      {/* <a href='https://maps.app.goo.gl/Sk35zeyvWw67T5HB9'>Google Maps</a> */}
      <div className="about-content mt-20">
        <h1 className="about-title">Discover Your True Self with Mantra Mountain</h1>
        <div className="about-card">
          <div className="card-face card-front">
            <h2>More Than a Trek, It's Your Journey to Freedom</h2>
            <p>Embark on a transformative Himalayan adventure that will awaken your spirit, and give back to the communities that make it possible.</p>
          </div>
          <div className="card-face card-back">
            <h2>Why Choose Mantra Mountain?</h2>
            <p>
              Discover untouched terrains and hidden parts of yourself, 
              Foster deep connections with nature, culture, and fellow travelers,
              Escape the stress of daily life into mountain serenity,
              Embark on a journey of self-discovery.
            </p>
          </div>
        </div>
        <div className="about-features">
          <div className="feature">
            <i className="fas fa-mountain"></i>
            <h3>Unplug, Connect, Explore More</h3>
            <p>Leave behind the digital noise and immerse yourself in the raw beauty of the Himalayas.</p>
          </div>
          <div className="feature">
            <i className="fas fa-users"></i>
            <h3>Journey with Expert Companions</h3>
            <p>Our guides don't just lead the way; they inspire transformation and ensure your safety.</p>
          </div>
          <div className="feature">
            <i className="fas fa-heart"></i>
            <h3>Give Back as You Discover</h3>
            <p>
              20% of our profits contribute to a disaster relief fund supporting remote Himalayan communities. Your trek makes a difference.
            </p>
          </div>
        </div>
        <div className="about-cta mb-5">
          <h2>Ready to Find Yourself Among the Peaks?</h2>
          <Link to="/Packages" className="cta-link">
            <button className="cta-button">Begin Your Transformation</button>
          </Link>
        </div>

      <AboutUs/>

        <div className="about-mantra">
          <h2>Our Guiding Light</h2>
          <p>
            "Lead us from the unreal to the real<br />
            Lead us from darkness to light<br />
            Lead us from death to immortality<br />
            Aum peace, peace, peace!"
          </p>
          <p>
            This ancient mantra embodies the essence of your journey with Mantra Mountain. As you trek through the majestic Himalayas, let this mantra guide you to inner peace, clarity, and personal transformation.
          </p>
        </div>
        <div className="about-partner">
          <h2>Partnering for Unparalleled Experiences</h2>
          <img src={TravelHimalayaLogo} alt="Travel Himalaya Nepal Logo" className="partner-logo" />
          <p>Our partnership with Travel Himalaya Nepal ensures that your journey is not just a trip, but a life-changing experience crafted with expertise and passion.</p>
          <p>In addition, we collaborate with humanitarian organizations to empower mountain communities with disaster relief and development programs.</p>
        </div>
        <div className="about-disaster-relief">
          <h2>Supporting Mountain Communities</h2>
          <p>
            At Mantra Mountain, we are dedicated to uplifting the lives of those in the regions we explore. Through our disaster relief campaign, we work to rebuild infrastructure, provide immediate assistance, and support sustainable recovery efforts in remote Himalayan villages.
          </p>
          <p>
            By choosing Mantra Mountain, you’re not just embarking on a trek; you’re contributing to a brighter future for these resilient communities.
          </p>
        </div>
        
        <ChatBot />
        <CampaignSection/>
        
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default About;