import React, { useEffect, useState } from 'react';

const AdminApproval = () => {
  const [pendingBlogs, setPendingBlogs] = useState([]); // Pending blogs state
  const [pendingPackages, setPendingPackages] = useState([]); // Pending packages state
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchPendingApprovals = async () => {
      setLoading(true); // Start loading state
      setError(null); // Reset error state

      try {
        const token = localStorage.getItem('adminToken');

        // Fetch pending blogs
        const blogResponse = await fetch('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/pending', {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!blogResponse.ok) throw new Error('Failed to fetch pending blogs');
        const blogs = await blogResponse.json();

        // Fetch pending packages
        const packageResponse = await fetch('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/package/pending', {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!packageResponse.ok) throw new Error('Failed to fetch pending packages');
        const packages = await packageResponse.json();

        // Update state with the fetched data
        setPendingBlogs(blogs);
        setPendingPackages(packages);
      } catch (err) {
        console.error('Error fetching pending approvals:', err);
        setError('Failed to load pending approvals'); // Update error state
      } finally {
        setLoading(false); // End loading state
      }
    };

    fetchPendingApprovals();
  }, []);

  const handleApproval = async (type, id, approve) => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await fetch(
        `https://nodejs-serverless-function-express-henna-theta.vercel.app/api/admin/approve/${type}/${id}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ approve }),
        }
      );

      if (!response.ok) throw new Error('Approval failed');

      // Update the respective state based on the approval type
      if (type === 'blog') {
        setPendingBlogs((prev) => prev.filter((blog) => blog._id !== id));
      } else if (type === 'package') {
        setPendingPackages((prev) => prev.filter((pkg) => pkg._id !== id));
      }
    } catch (err) {
      console.error('Error updating approval status:', err);
      setError('Failed to update approval status');
    }
  };

  const handleReject = async (type, id, approve) => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await fetch(
        `https://nodejs-serverless-function-express-henna-theta.vercel.app/api/admin/reject/${type}/${id}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ approve }),
        }
      );

      if (!response.ok) throw new Error('Approval failed');

      // Update the respective state based on the approval type
      if (type === 'blog') {
        setPendingBlogs((prev) => prev.filter((blog) => blog._id !== id));
      } else if (type === 'package') {
        setPendingPackages((prev) => prev.filter((pkg) => pkg._id !== id));
      }
    } catch (err) {
      console.error('Error updating approval status:', err);
      setError('Failed to update approval status');
    }
  };

  return (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold">Pending Approvals</h2>

      {/* Display error message */}
      {error && <p className="text-red-500">{error}</p>}

      {/* Display loading state */}
      {loading ? (
        <p>Loading pending approvals...</p>
      ) : (
        <div className="space-y-4">
          {/* Pending Blogs */}
          <h3 className="text-lg font-semibold">Pending Blogs</h3>
          {pendingBlogs.length > 0 ? (
            pendingBlogs.map((blog) => (
              <div
                key={blog._id}
                className="bg-white p-4 rounded-lg shadow-md border"
              >
                <h4 className="font-medium">{blog.title}</h4>
                <p>{blog.content}</p>
                <div className="mt-4 space-x-4">
                  <button
                    onClick={() => handleApproval('blog', blog._id, true)}
                    className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
                  >
                    Approve
                  </button>
                  <button
                    onClick={() => handleApproval('blog', blog._id, false)}
                    className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                  >
                    Reject
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No pending blogs at the moment.</p>
          )}

          {/* Pending Packages */}
          <h3 className="text-lg font-semibold mt-6">Pending Packages</h3>
          {pendingPackages.length > 0 ? (
            pendingPackages.map((pkg) => (
              <div
                key={pkg._id}
                className="bg-white p-4 rounded-lg shadow-md border"
              >
                <h4 className="font-medium">{pkg.name}</h4>
                <p>{pkg.description}</p>
                <div className="mt-4 space-x-4">
                  <button
                    onClick={() => handleApproval('package', pkg._id, true)}
                    className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
                  >
                    Approve
                  </button>
                  <button
                    onClick={() => handleReject('package', pkg._id, false)}
                    className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                  >
                    Reject
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No pending packages at the moment.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminApproval;
