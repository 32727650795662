import React, { useState, useMemo, useCallback } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./CreateBlog.css";

const API_ENDPOINT = "https://nodejs-serverless-function-express-henna-theta.vercel.app/api/create";

const CreateBlog = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  // Memoized image upload handler for ReactQuill
  const handleImageUpload = useCallback(() => {
    return new Promise((resolve, reject) => {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();

      input.onchange = async () => {
        const file = input.files[0];
        if (!file) return;

        try {
          const formData = new FormData();
          // Using placeholder text for title and content in this request;
          // adjust if needed for your API
          formData.append("image", file);
          formData.append("title", "placeholder");
          formData.append("content", "placeholder");

          const response = await axios.post(API_ENDPOINT, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          if (response.data.blogPost?.image) {
            resolve(response.data.blogPost.image);
          } else {
            reject(new Error("Image upload failed"));
          }
        } catch (error) {
          console.error("Error uploading image:", error);
          reject(error);
        }
      };
    });
  }, []);

  // Memoize the ReactQuill modules so they don't get re-created on every render.
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["blockquote", "code-block"],
        ["link", "image"],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["clean"],
      ],
      handlers: {
        image: async function () {
          const editor = this.quill;
          const imageUrl = await handleImageUpload();
          if (imageUrl) {
            const range = editor.getSelection();
            // Insert the image into the editor at the current cursor position
            editor.insertEmbed(range.index, "image", imageUrl);
          }
        },
      },
    },
  }), [handleImageUpload]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("content", content);
      if (image) formData.append("image", image);

      const response = await axios.post(API_ENDPOINT, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      alert(response.data.message || "Blog created successfully!");
      setTitle("");
      setContent("");
      setImage(null);
    } catch (error) {
      console.error("Error creating blog:", error);
      alert("Failed to create blog. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="blog-post-container">
      <h2 className="blog-post-title">Create Blog Post</h2>
      <form onSubmit={handleSubmit} className="blog-post-form">
        <div className="form-group">
          <label className="form-label">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="form-input"
            placeholder="Enter blog title"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Content</label>
          <ReactQuill
            theme="snow"
            value={content}
            onChange={setContent}
            modules={modules}
            placeholder="Write your blog here..."
          />
        </div>

        <div className="form-group">
          <label className="form-label">Featured Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])}
            className="form-input"
          />
        </div>

        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? "Creating Blog..." : "Create Blog"}
        </button>
      </form>
    </div>
  );
};

export default CreateBlog;
