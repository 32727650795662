import React, { useState } from 'react';
import './Sidebar.css';
import { FaSearch, FaFilter, FaChevronDown } from 'react-icons/fa';
import BookingCalendar from '../bookingCalendar/BookingCalendar';

const Sidebar = ({ onFilterChange, onDateChange }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [duration, setDuration] = useState('');
  const [difficulty, setDifficulty] = useState('');
  const [region, setRegion] = useState('');
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
    // Call the filter function to apply filters
    onFilterChange({ searchQuery: e.target.value.toLowerCase(), duration, difficulty, region });
  };

  const handleDurationChange = (e) => {
    setDuration(e.target.value);
    // Pass updated filter values to the parent component
    onFilterChange({ searchQuery, duration: e.target.value, difficulty, region });
  };

  const handleDifficultyChange = (e) => {
    setDifficulty(e.target.value);
    // Pass updated filter values to the parent component
    onFilterChange({ searchQuery, duration, difficulty: e.target.value, region });
  };

  const handleRegionChange = (e) => {
    setRegion(e.target.value);
    // Pass updated filter values to the parent component
    onFilterChange({ searchQuery, duration, difficulty, region: e.target.value });
  };

  return (
    <div className="bg-white shadow-md p-4 mb-6 rounded-lg">
      {/* Search Bar (Always Visible) */}
      <div className="flex items-center gap-4 mb-4">
        <div className="relative flex-1">
          <input
            id="search"
            type="text"
            placeholder="Search packages..."
            value={searchQuery}
            onChange={handleSearch}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          <FaSearch className="absolute left-3 top-3 text-gray-400" />
        </div>

        {/* Toggle Filters Button */}
        <button
          onClick={() => setShowMoreFilters(!showMoreFilters)}
          className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
        >
          <FaFilter />
          {showMoreFilters ? 'Less Filters' : 'More Filters'}
          <FaChevronDown className={`transition-transform ${showMoreFilters ? 'rotate-180' : ''}`} />
        </button>
      </div>

      {/* Additional Filters (Hidden by Default) */}
      {showMoreFilters && (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
          {/* Duration Filter */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Duration</label>
            <select
              value={duration}
              onChange={handleDurationChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="">All</option>
              <option value="3 Days">3 days</option>
              <option value="4 Days">4 days</option>
              <option value="6 Days">6 days</option>
              <option value="10 Days">10 days</option>
              <option value="11 Days">11 days</option>
              <option value="12 Days">12 days</option>
              <option value="16 Days">16 days</option>
              <option value="Flexible ">Flexible </option>
            </select>
          </div>

          {/* Difficulty Filter */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Difficulty</label>
            <select
              value={difficulty}
              onChange={handleDifficultyChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="">All</option>
              <option value="Easy to Moderate">Easy</option>
              <option value="Moderate">Moderate</option>
              <option value="Moderate to Challenging">Moderate to Challenging</option>
            </select>
          </div>

          {/* Region Filter */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Region</label>
            <select
              value={region}
              onChange={handleRegionChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="">All Nepal</option>
              <option value="everest">Everest</option>
              <option value="annapurna">Annapurna</option>
              <option value="langtang">Langtang</option>
              <option value="manaslu region">Manaslu</option>
            </select>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
