import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { motion } from 'framer-motion';
import { FaClock, FaUser, FaCalendar, FaShareAlt, FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import Footer from "../footer/Footer.jsx";

const BlogPost = () => {
    const { slug } = useParams();
    const [blog, setBlog] = useState(null);
    const [headings, setHeadings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [activeHeading, setActiveHeading] = useState('');

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`https://nodejs-serverless-function-express-henna-theta.vercel.app/api/${slug}`);
                setBlog(response.data);

                // Only extract headings if they're not already in the response
                if (!response.data.tableOfContents) {
                    const tempDiv = document.createElement('div');
                    tempDiv.innerHTML = response.data.content;
                    const headingElements = tempDiv.querySelectorAll('h2');
                    const tocItems = Array.from(headingElements).map(heading => heading.textContent);
                    setHeadings(tocItems);
                } else {
                    setHeadings(response.data.tableOfContents);
                }
            } catch (error) {
                setError(error.response?.data?.message || 'Failed to fetch blog');
            } finally {
                setLoading(false);
            }
        };

        if (slug) fetchBlog();
    }, [slug]);

    useEffect(() => {
        const handleScroll = () => {
            const headingElements = document.querySelectorAll('h2[id]');
            headingElements.forEach(heading => {
                const rect = heading.getBoundingClientRect();
                if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
                    setActiveHeading(heading.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-50 via-white to-indigo-50">
                <div className="w-16 h-16 border-4 border-indigo-600 border-t-transparent rounded-full animate-spin"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="text-red-500 text-xl font-medium bg-red-50 px-6 py-4 rounded-lg">
                    {error}
                </div>
            </div>
        );
    }

    if (!blog) return null;

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="min-h-screen bg-gradient-to-br from-indigo-50 via-white to-indigo-50 mt-20"
        >
            {/* Enhanced Hero Section */}
            <div className="relative h-[70vh] overflow-hidden">
                <div className="absolute inset-0">
                    <img
                        src={blog.image}
                        alt={blog.title}
                        className="w-full h-full object-cover transform scale-105 hover:scale-100 transition-transform duration-700"
                    />
                    <div className="absolute inset-0 bg-gradient-to-b from-black/70 via-black/50 to-black/70 backdrop-blur-[1px]"></div>
                </div>
                {blog.images.length > 0 ? (
                    <div className="flex gap-4 overflow-x-auto">
                        {blog.images.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Blog Image ${index + 1}`}
                                className="w-full h-full  object-top transform scale-105 hover:scale-100 transition-transform duration-700"
                            />
                        ))}
                    </div>
                ) : (
                    <div>
                        <p>No images available.</p>
                    </div>
                )}

                <div className="absolute inset-0 flex items-center justify-center px-4">
                    <div className="text-center max-w-4xl ">
                        <motion.span
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.1 }}
                            className="inline-block px-4 py-2 rounded-full bg-indigo-600/20 text-indigo-100 backdrop-blur-sm mb-4"
                        >
                            Travel Blog
                        </motion.span>
                        <motion.h1
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.2 }}
                            className="text-4xl md:text-6xl font-bold text-white mb-6 leading-tight"
                        >
                            {blog.title}
                        </motion.h1>
                        <motion.div
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.3 }}
                            className="flex flex-wrap items-center justify-center gap-6 text-gray-200"
                        >
                            <div className="flex items-center bg-black/30 rounded-full px-4 py-2 backdrop-blur-sm">
                                <FaUser className="mr-2 text-indigo-400" />
                                <span>By Admin</span>
                            </div>
                            <div className="flex items-center bg-black/30 rounded-full px-4 py-2 backdrop-blur-sm">
                                <FaCalendar className="mr-2 text-indigo-400" />
                                <span>{new Date(blog.createdAt).toLocaleDateString()}</span>
                            </div>
                            <div className="flex items-center bg-black/30 rounded-full px-4 py-2 backdrop-blur-sm">
                                <FaClock className="mr-2 text-indigo-400" />
                                <span>5 min read</span>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4 py-12 max-w-7xl">
                <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
                    {/* Main Content */}
                    <div className="lg:col-span-8 lg:col-start-3">
                        <article className="prose prose-lg max-w-none">
                            <ReactMarkdown
                                children={blog.content}
                                remarkPlugins={[remarkGfm]}
                                rehypePlugins={[rehypeRaw]}
                                components={{
                                    h2: ({ node, ...props }) => (
                                        <h2
                                            id={props.children}
                                            className="text-3xl font-bold text-gray-900 mt-12 mb-6 scroll-mt-24 border-b border-indigo-100 pb-4"
                                        >
                                            {props.children}
                                        </h2>
                                    ),
                                    p: ({ node, ...props }) => (
                                        <p className="text-gray-700 leading-relaxed mb-6 text-lg">
                                            {props.children}
                                        </p>
                                    ),
                                    img: ({ node, ...props }) => (
                                        <div className="my-8 group">
                                            <div className="overflow-hidden rounded-xl shadow-lg">
                                                <img
                                                    {...props}
                                                    className="w-full object-cover transform transition-transform duration-500 group-hover:scale-105"
                                                    alt={props.alt || "Blog image"}
                                                />
                                            </div>
                                            {props.alt && (
                                                <p className="text-sm text-gray-500 mt-3 text-center italic">
                                                    {props.alt}
                                                </p>
                                            )}
                                        </div>
                                    ),
                                }}
                            />
                        </article>

                        {/* Enhanced Share Section */}
                        <div className="mt-12 p-8 bg-white rounded-2xl shadow-sm border border-indigo-50">
                            <div className="flex flex-col md:flex-row items-center justify-between gap-4">
                                <h3 className="text-xl font-semibold text-gray-900">
                                    Share this article
                                </h3>
                                <div className="flex space-x-4">
                                    {['facebook', 'twitter', 'linkedin'].map((platform) => (
                                        <button
                                            key={platform}
                                            className="p-3 bg-indigo-50 hover:bg-indigo-100 rounded-full 
                                                transition-all duration-300 hover:shadow-md hover:-translate-y-1"
                                        >
                                            {platform === 'facebook' && <FaFacebook className="text-indigo-600 text-xl" />}
                                            {platform === 'twitter' && <FaTwitter className="text-indigo-600 text-xl" />}
                                            {platform === 'linkedin' && <FaLinkedin className="text-indigo-600 text-xl" />}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Floating Table of Contents */}
                    {headings.length > 0 && (
                        <div className="hidden lg:block lg:col-span-2 lg:col-start-11">
                            <div className="sticky top-24">
                                <div className="bg-white/80 backdrop-blur-md rounded-2xl shadow-sm border border-indigo-50 p-6">
                                    <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
                                        <span className="w-8 h-1 bg-indigo-600 rounded-full mr-3"></span>
                                        Contents
                                    </h3>
                                    <nav className="space-y-2">
                                        {headings.map((heading, index) => (
                                            <a
                                                key={index}
                                                href={`#${heading}`}
                                                className={`block py-2 px-3 rounded-lg transition-all duration-200 text-sm
                                                    ${activeHeading === heading
                                                        ? 'bg-indigo-50 text-indigo-600 font-medium'
                                                        : 'text-gray-600 hover:bg-gray-50'
                                                    }`}
                                            >
                                                {heading}
                                            </a>
                                        ))}
                                    </nav>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </motion.div>
    );
};

export default BlogPost; 