import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Users, Package, FileText, LogOut, Mail } from 'lucide-react';
import PackageUpload from './PackageUpload';
import CreateBlog from './CreateBlog';
import BlogList from '../BlogPost/BlogList'
import Adminbloglist from './Adminbloglist';
import Adminpackagelist from './Adminpackagelist';
import AdminApproval from './AdminApproval';
import AdminUser from './AdminUser';

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [stats, setStats] = useState({
    users: [],
    totalPackages: 0,
    totalBlogs: 0,
    messages: []
  });
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [pendingPackages, setPendingPackages] = useState([]);
  const [pendingBlogs, setPendingBlogs] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const verifyAuth = async () => {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        navigate('/admin/login');
        return;
      }

      try {
        const response = await fetch('/api/auth/verify', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        
        if (!response.ok) {
          localStorage.removeItem('adminToken');
          navigate('/admin/login');
        }
      } catch (error) {
        console.error('Auth verification failed:', error);
        localStorage.removeItem('adminToken');
        navigate('/admin/login');
      }
    };

    verifyAuth();
  }, [navigate]);



  useEffect(() => {
    const fetchStats = async () => {
      try {
        const token = localStorage.getItem('adminToken');
        if (!token) {
          navigate('/admin/login');
          return;
        }
        
        const response = await fetch('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/users/all-users', {
          // const response = await fetch('nodejs-serverless-function-express-if1w0hmfz.vercel.app/api/users/all-users', {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch stats');
        }

        const data = await response.json();
        setStats({
          users: data || [],
          totalPackages: data.totalPackages || 0,
          totalBlogs: data.totalBlogs || 0,
          messages: data.messages || []
        });
      } catch (err) {
        setError('Failed to load dashboard data');
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

 
  // const fetchUsers = async () => {
  //   try {
  //     const token = localStorage.getItem('adminToken');
  //     const response = await fetch('https://nodejs-serverless-function-express-henna-theta.vercel.app/admin/users', {
  //       headers: { 'Authorization': `Bearer ${token}` }
  //     });

  //     if (!response.ok) throw new Error('Failed to fetch users');

  //     const data = await response.json();
  //     setUsers(data.users || []);
  //   } catch (err) {
  //     setError('Failed to load users');
  //   }
  // };

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    navigate('/admin/login');
  };

  const StatCard = ({ title, value, icon: Icon }) => (
    <div className="bg-white p-6 rounded-lg shadow-md border">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm text-gray-500">{title}</p>
          <p className="text-2xl font-semibold">{value}</p>
        </div>
        <Icon className="text-indigo-600" size={24} />
      </div>
    </div>
  );

  const renderContent = () => {
    switch (activeTab) {
      case 'overview':
        return (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <StatCard title="Total Users" value={stats.users.length} icon={Users} />
              <StatCard title="Total Packages" value={stats.totalPackages} icon={Package} />
              <StatCard title="Total Blogs" value={stats.totalBlogs} icon={FileText} />
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md border">
              <h3 className="text-lg font-semibold mb-4">Recent Contact Messages</h3>
              <div className="space-y-4">
                {stats.messages.map((message, index) => (
                  <div key={index} className="border-b pb-4 last:border-0">
                    <div className="flex justify-between items-start">
                      <div>
                        <p className="font-medium">{message.name}</p>
                        <p className="text-sm text-gray-500">{message.email}</p>
                      </div>
                      <span className="text-xs text-gray-500">
                        {new Date(message.date).toLocaleDateString()}
                      </span>
                    </div>
                    <p className="mt-2 text-sm">{message.message}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );

      case 'users':
        return <AdminUser/>;

      case 'packages':
        return <Adminpackagelist/>;

      case 'blogs':
        return <Adminbloglist/> ;

        case 'approvals':
          return <AdminApproval />;
          
        
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <h1 className="text-xl font-bold text-gray-900">Admin Dashboard</h1>
            </div>
            <div className="flex items-center">
              <button
                onClick={handleLogout}
                className="flex items-center text-gray-600 hover:text-gray-900"
              >
                <LogOut className="mr-2" size={20} />
                Logout
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
            {error}
          </div>
        )}

        <div className="flex space-x-4 mb-6">
          <button
            onClick={() => setActiveTab('overview')}
            className={`px-4 py-2 rounded-md ${
              activeTab === 'overview'
                ? 'bg-indigo-600 text-white'
                : 'bg-white text-gray-800'
            }`}
          >
            Overview
          </button>
          <button
            onClick={() => setActiveTab('users')}
            className={`px-4 py-2 rounded-md ${
              activeTab === 'users' ? 'bg-indigo-600 text-white' : 'bg-white text-gray-800'
            }`}
          >
            Users
          </button>
          <button
            onClick={() => setActiveTab('packages')}
            className={`px-4 py-2 rounded-md ${
              activeTab === 'packages' ? 'bg-indigo-600 text-white' : 'bg-white text-gray-800'
            }`}
          >
            Packages
          </button>
          <button
            onClick={() => setActiveTab('blogs')}
            className={`px-4 py-2 rounded-md ${
              activeTab === 'blogs' ? 'bg-indigo-600 text-white' : 'bg-white text-gray-800'
            }`}
          >
            Blogs
          </button>
          <button
            onClick={() => setActiveTab('approvals')}
            className={`px-4 py-2 rounded-md ${
              activeTab === 'approvals' ? 'bg-indigo-600 text-white' : 'bg-white text-gray-800'
            }`}
          >
            Approvals
          </button>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md border">{renderContent()}</div>
      </div>
    </div>
  );
};

export default AdminDashboard;
