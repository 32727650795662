import React, { useState, useEffect, useRef } from 'react';
import './Footer.css';
import instagramLogo from '../../assets/instagram-logo.png';
import whatsappLogo from '../../assets/whatsapp-logo.png';
import mantraAudio from '../../assets/mantra-audio.mp3';
import gmaps from './../../assets/gmaps.png'


const Footer = () => {
  const [mantraText, setMantraText] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);
  const [explanationText, setExplanationText] = useState('');
  const audioRef = useRef(null);
  const fullMantra = "Om Asato Maa Sad-Gamaya | Tamaso Maa Jyotir-Gamaya | Mrtyor-Maa Amrtam Gamaya | Om Shaantih Shaantih Shaantih ||";
  const explanationFullText = `.Lead us from the unreal to the real\nLead us from darkness to light\nLead us from death to immortality\nAum peace, peace, peace!\nThis ancient mantra embodies the essence of Mantra Mountain's philosophy. It is a prayer for spiritual transformation and enlightenment, guiding us to transcend illusions and falsehoods, to gain knowledge and clarity, and to overcome the fear of death, achieving eternal peace. Just as our treks lead you through the majestic Himalayas, this mantra leads you to inner peace and positive energy.`;


  useEffect(() => {
    setMantraText(fullMantra);
  }, []);

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.currentTime = 40; // Skip first 40 seconds
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleExplanation = () => {
    setShowExplanation(!showExplanation);
    if (!showExplanation) {
      let index = 0;
      const intervalId = setInterval(() => {
        setExplanationText((prev) => prev + explanationFullText[index]);
        index++;
        if (index === explanationFullText.length) {
          clearInterval(intervalId);
        }
      }, 50);
    } else {
      setExplanationText('');
    }
  };

  return (
    <footer>
      <div className="footer-content" style={{justifyContent:'space-between'}}>
        <p>© 2023 Mantra Mountain.</p>
    
        <div >
        <div style={{display:'flex' }}>
        <a href="https://www.instagram.com/mantramountain/" target="_blank" rel="noopener noreferrer" className="instagram-link">
          <img src={instagramLogo} alt="Instagram" className="instagram-logo" />
        </a>
      <a href="https://wa.me/message/ONCGLHPEKNRMP1" target="_blank" rel="noopener noreferrer" className="instagram-link">
        <img src={whatsappLogo} alt="whatsapp" className="Whatsapp" />
      </a>
      <a href="https://maps.app.goo.gl/UCkZ5CbK56GjX5Jd6" target="_blank" rel="noopener noreferrer" className="instagram-link">
        <img 
        src={gmaps} alt="maps" className="instagram-logo" />
      </a>
     
      </div>
        </div>
        
      </div>
      <div className="mantra-wave-container">
        <div className="mantra-text-container">
          <div className="mantra-text">{mantraText}</div>
        </div>
        <div className="control-buttons">
          <button onClick={handlePlayPause} className="control-button">
            {isPlaying ? '⏸' : '▶'}
          </button>
          <button onClick={handleExplanation} className="control-button">
            ℹ️
          </button>
        </div>
        {showExplanation && (
          <div className="explanation-text">
            {explanationText.split('\n').map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </div>
        )}
        <svg className="waves" xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
            <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
            <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
            <use href="#gentle-wave" x="48" y="7" fill="#fff" />
          </g>
        </svg>
        
      </div>
      <audio ref={audioRef} src={mantraAudio} />
     
    </footer>
  );
};

export default Footer;
