import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './PackageList.css';
import Confetti from 'react-dom-confetti';
import { FaGlobe, FaMapMarkerAlt, FaWalking, FaMountain, FaListAlt } from 'react-icons/fa';
import { LiaCalendarDaySolid } from "react-icons/lia";
import BookingCalendar from '../bookingCalendar/BookingCalendar';
import Payment from '../Payment/Payment';
import CampaignSection from '../campaign/CampaignSection';
import Footer from '../footer/Footer';
import Sidebar from '../Sidebar/Sidebar';
import { Atom } from 'lucide-react';
import { OrbitProgress } from 'react-loading-indicators';




const PackageList = () => {
  const [packages, setPackages] = useState([]);
  const [packageDetails, setPackageDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);

  // const [selectedPackage, setSelectedPackage] = useState(null);
  // const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  // const [bookingDetails, setBookingDetails] = useState(null);
  const navigate = useNavigate();
  // const [searchText, setSearchText] = useState('');


  const [filteredPackages, setFilteredPackages] = useState([]);
  useEffect(() => {
    setFilteredPackages(packages);
  }, [packages]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/package/all');
        if (Array.isArray(response.data)) {
          setPackages(response.data);
          setFilteredPackages(response.data);
        } else {
          throw new Error('Invalid data format');
        }
      } catch (error) {
        console.error('Error fetching packages:', error);
        setError(error.response?.data?.message || 'Failed to load packages');
      } finally {
        setLoading(false);
      }
    };
    fetchPackages();
  }, []);

  // Updated to use slug instead of id
  const handlePackageClick = (slug) => {
    try {
      setLoading(true);
      // axios.get(`https://nodejs-serverless-function-express-henna-theta.vercel.app/api/packages/slug/${slug}`);
      axios.get(`https://nodejs-serverless-function-express-henna-theta.vercel.app/api/package/${slug}`);
      
      console.log(slug)
      // setPackageDetails(response.data)
      console.log(navigate(`/packages/${slug}`))
      navigate(`/packages/${slug}`);

    } catch (error) {
      console.error('Error fetching package:', error);
      setError(error.response?.data?.message || 'Failed to load package details');
    }
  };
  const handleDateChange = (selectedDate) => {
    console.log('Selected date:', selectedDate);
    // Handle the date change logic here
  };

  // const handleBookNow = (pkg) => {
  //   setSelectedPackage(pkg);
  // };

  // const handleCloseBooking = () => {
  //   setSelectedPackage(null);
  // };

  // const handleSearchChange = (event) => {
  //   setSearchText(event.target.value.toLowerCase()); // Search should be case-insensitive
  // };

  // const handleBooking = (date, formData) => {
  //   setBookingDetails({ packageData: selectedPackage, date, formData });
  //   setIsPaymentOpen(true);
  // };

  // const handlePaymentSuccess = () => {
  //   setShowConfetti(true);
  //   setTimeout(() => setShowConfetti(false), 3000);
  //   setIsPaymentOpen(false);
  //   setSelectedPackage(null);
  // };

  // const handlePaymentCancel = () => {
  //   setIsPaymentOpen(false);
  //   setSelectedPackage(null);
  // };


  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  // Add new state for filter visibility
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  if (loading)
          return (
            <div className="flex items-center justify-center h-screen">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
            </div>
          );
  if (error) return <p>Error: {error}</p>;

  const containerStyle = {
    display: "flex",

    // justifyContent:"center"
    // justifyContent:"space-between"
    // margin: "20%"
  }
  const handleFilterApply = ({ searchQuery, duration, difficulty, region }) => {
    const filtered = packages.filter((pkg) => {
      const matchesSearch = pkg.title.toLowerCase().includes(searchQuery.toLowerCase() || '');
      const matchesDuration = !duration || pkg.duration === duration;
      const matchesDifficulty = !difficulty || pkg.difficulty === difficulty;
      const matchesRegion = !region || pkg.region.toLowerCase().includes(region.toLowerCase());
      return matchesSearch && matchesDuration && matchesDifficulty && matchesRegion;
    });
    setFilteredPackages(filtered);
  };


  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-50 to-slate-100 pt-24">
      {/* Enhanced Hero Section */}
      {/* <div className="relative h-[60vh] mb-12">
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1464822759023-fed622ff2c3b')] bg-cover bg-center">
          <div className="absolute inset-0 bg-black/40 backdrop-blur-[2px]"></div>
        </div>
        <div className="relative h-full flex flex-col items-center justify-center text-white px-4 space-y-6">
          <h1 className="text-5xl md:text-6xl font-bold mb-4 text-center leading-tight">
            Your Next Adventure <br/> Awaits
          </h1>
          <p className="text-xl md:text-2xl text-center max-w-2xl text-gray-200 leading-relaxed">
            Discover breathtaking trails and create memories that last a lifetime
          </p>
          <div className="flex gap-4 mt-8">
            <button className="px-8 py-3 bg-indigo-600 text-white rounded-full hover:bg-indigo-700 transition-all transform hover:scale-105 duration-300 shadow-lg">
              Explore Packages
            </button>
            <button className="px-8 py-3 bg-white/10 backdrop-blur-md text-white rounded-full hover:bg-white/20 transition-all transform hover:scale-105 duration-300 border border-white/30">
              Watch Video
            </button>
          </div>
        </div>
      </div> */}

      {/* Trust Indicators */}
      {/* <div className="max-w-7xl mx-auto px-4 mb-16">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 bg-white rounded-2xl p-8 shadow-lg">
          <div className="flex flex-col items-center text-center">
            <div className="text-4xl font-bold text-indigo-600 mb-2">1000+</div>
            <div className="text-gray-600">Happy Trekkers</div>
          </div>
          <div className="flex flex-col items-center text-center">
            <div className="text-4xl font-bold text-indigo-600 mb-2">50+</div>
            <div className="text-gray-600">Unique Trails</div>
          </div>
          <div className="flex flex-col items-center text-center">
            <div className="text-4xl font-bold text-indigo-600 mb-2">100%</div>
            <div className="text-gray-600">Safety Record</div>
          </div>
          <div className="flex flex-col items-center text-center">
            <div className="text-4xl font-bold text-indigo-600 mb-2">4.9★</div>
            <div className="text-gray-600">Customer Rating</div>
          </div>
        </div>
      </div> */}

      <div className=" mx-auto sm:px-4 lg:px-6">
        {/* Filter Toggle Button - Only visible on mobile */}
        <button 
          onClick={() => setIsFilterVisible(!isFilterVisible)}
          className="md:hidden w-full px-4 py-2 bg-indigo-600 text-white rounded-lg flex items-center justify-center"
        >
          <FaListAlt />
          {isFilterVisible ? 'Hide Filters' : 'Show Filters'}
        </button>

        {/* Filters - Horizontal on desktop, vertical on mobile */}
        <div className={`${isFilterVisible ? 'block' : 'hidden'} md:block`}>
          <div className="bg-white shadow-md rounded-lg">
            <Sidebar
              onFilterChange={handleFilterApply}
              onDateChange={handleDateChange}
            />
          </div>
        </div>

        {/* Package List */}
        <div className="mb-4">
          <h2 className="text-3xl font-bold text-gray-900">Featured Treks</h2>
          {/* <p className="text-gray-600">Handpicked adventures for the ultimate experience</p> */}
        </div>
        
        {/* Reduce gap between cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredPackages.map((pkg) => (
            <div
              key={pkg._id}
              className="group bg-white rounded-2xl shadow-lg hover:shadow-2xl transition-all duration-300 overflow-hidden cursor-pointer transform hover:-translate-y-2"
              onClick={() => handlePackageClick(pkg.slug)}
            >
              {/* Card Image - Adjust height for better fit in 3-column layout */}
              <div className="relative h-60 overflow-hidden">
                <img
                  src={pkg.overallImages[0]}
                  alt={pkg.title}
                  className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-700"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
                <div className="absolute top-4 right-4">
                  <span className="px-3 py-1 bg-white/90 text-indigo-600 rounded-full text-sm font-semibold shadow-lg">
                    {pkg.difficulty}
                  </span>
                </div>
                <div className="absolute bottom-4 left-4 text-white">
                  <p className="text-sm font-medium">Starting from</p>
                  <p className="text-2xl font-bold">${pkg.price}</p>
                </div>
              </div>

              {/* Card Content - Adjust padding and text sizes for better fit */}
              <div className="p-4">
                <h2 className="text-xl font-bold text-gray-800 mb-3 group-hover:text-indigo-600 transition-colors">
                  {pkg.title}
                </h2>
                
                <div className="grid grid-cols-2 gap-3 mb-4">
                  <div className="flex items-center text-gray-600">
                    <FaGlobe className="w-5 h-5 text-indigo-600 mr-2" />
                    <span>{pkg.country}</span>
                  </div>
                  <div className="flex items-center text-gray-600">
                    <FaMapMarkerAlt className="w-5 h-5 text-indigo-600 mr-2" />
                    <span>{pkg.region}</span>
                  </div>
                  <div className="flex items-center text-gray-600">
                    <LiaCalendarDaySolid className="w-5 h-5 text-indigo-600 mr-2" />
                    <span>{pkg.duration} days</span>
                  </div>
                  <div className="flex items-center text-gray-600">
                    <FaWalking className="w-5 h-5 text-indigo-600 mr-2" />
                    <span>{pkg.dailyDistance}</span>
                  </div>
                </div>

                <button
                  className="w-full px-4 py-2 bg-indigo-600 text-white rounded-xl hover:bg-indigo-700 transition-colors duration-200 flex items-center justify-center gap-2 group text-sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePackageClick(pkg.slug);
                  }}
                >
                  Explore Trek
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 group-hover:translate-x-1 transition-transform" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Call to Action Section */}
      <div className="bg-indigo-600 mt-20 py-16">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
            Ready for Your Next Adventure?
          </h2>
          <p className="text-xl text-indigo-100 mb-8">
            Join thousands of happy trekkers who've discovered their perfect journey with us
          </p>
          <button className="px-8 py-4 bg-white text-indigo-600 rounded-full hover:bg-indigo-50 transition-colors text-lg font-semibold">
            Start Planning Today
          </button>
        </div>
      </div>

      <div className="mt-20">
        <CampaignSection />
      </div>
      
      <Footer />
    </div>
  );
};

export default PackageList;


